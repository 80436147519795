import styled from 'styled-components'
import { Link } from "gatsby"

export const HeaderWrapper = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
`

export const StyledLogoLink = styled(Link)`
  display: inline-block;
  height: 100%;

  img {
    vertical-align: top;
  }
`

export const LogoLinkWrapper = styled.span`
  max-width: 200px;
`
