/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"

import { 
  Main,
  SiteBackground,
  GlobalStyle
} from './styled'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        logo: file(relativePath: { regex: "/logo_white.png/" }) {	
          childImageSharp {	
            fluid(maxWidth: 300) {	
              src	
            }	
          }	
        }
      }
    `}
    render={({ logo, site }) => {
      try {
        console.log('MY STUFF', logo.childImageSharp.fluid.src || logo.childImageSharp.fluid)
      } catch(e){
        console.log(e)
      }
      return (
      <SiteBackground>
        <GlobalStyle/>
        <Header siteTitle={site.siteMetadata.title}/>
        <Main>
          {children}
        </Main>
        <Footer />
      </SiteBackground>
    )}}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
