import styled, { css } from 'styled-components'
import { centeredCss } from '../utils/styled'
import { padding } from 'polished'
import ArrowSVG from './ArrowSVG';

export const getPadding = ({ top, right, left, bottom }) => {
  return [top, right, bottom, left]
}

export const cssMarginsFromProps = css`
  ${props => props.marginBottom && css`margin-bottom: ${props.marginBottom}rem;`}
  ${props => props.marginTop && css`margin-top: ${props.marginTop}rem;`}
`

export const CenteredDiv = styled.div`
  ${centeredCss}
`

export const CenteredSection = styled.section`
  ${centeredCss}

  ${props => props.height
    && css`
      height:${props.height}vh;
    `}

  min-height: max-content;

  > * {
    width: 100%;
  }
`

export const SectionContentWrapper = styled.div`${cssMarginsFromProps}`

/**
 * Side by side components
 */

export const sideBySideTitleStyles = css`
  font-weight: 700;
  text-transform: none;
  align-self: flex-start;
  color: rgb(115, 115, 125);
  line-height: 1.4;
  min-width: 10rem;
  margin-right: 2rem;
  margin-bottom: 0;
`

export const SideBySideH1 = styled.h1`
  ${sideBySideTitleStyles}
  font-size: 1.5rem;
`

export const SideBySideH2 = styled.h2`
  ${sideBySideTitleStyles}
  font-size: 1.5rem;
`

export const SideBySideP = styled.p`
  margin-bottom: 0;
`

export const SideBySideWrapper = styled.span`
  ${cssMarginsFromProps}
  display: flex;
`

/**
 * Services
 */

export const ServicesP = styled.p`
  margin-bottom: 2rem;
`

export const HorizontalDivisionLine = styled.div`
  border-bottom: white solid 1px;
  margin-left: auto;
  margin-right: auto;
  width: 3rem;

  ${cssMarginsFromProps}
`

/**
 * Contact
 */

export const WorkWithUsP = styled.p`
  margin-left: 1rem;
`

export const ContactSectionWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`

export const ContactLink = styled.a`
  text-decoration: none;
  color: white;
`

export const CenteredArrowSVG = styled.span`
  align-items: center;
  display: flex;
`

export const StyledArrowSVG = styled.span`
  margin-right: 2rem;
`

export const LinkListItem = styled.li`
  cursor: pointer;

  & :hover {
    ${ContactLink} {
      font-weight: bold;
      text-decoration: underline;
    }

    ${StyledArrowSVG} {
      transition: 200ms transform ease-in;
      transform: translateX(15px);
    }
  }
`

export const LinkList = styled.ul`
  list-style: none;
  margin: 0;
`