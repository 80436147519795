import { css } from 'styled-components'


/**
 * TODO: 
 * 1) Make media queries for: xs, sm, md, lg, xs-md
 * 2) Make css that is extended with media queries for mobile styles
 */

export const centeredCss = css`
  position: static;
  width: 100%;
  max-width: 1220px;
  display: flex;
  margin: 0px auto;
  padding: 0px 4rem;
  background: transparent;
  align-items: center;
`
