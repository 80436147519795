import React from "react"
import PropTypes from "prop-types"

import {
  HeaderWrapper,
  LogoLinkWrapper,
  StyledLogoLink
} from './styled'

import {
  CenteredDiv
} from '../../MainPage/styled'
import logo from '../../../assets/images/icon/logo_white.png'

const Header = () => {
  return (
    <CenteredDiv>
      <HeaderWrapper>
        <LogoLinkWrapper>
          <StyledLogoLink to="/">
            <img
              alt="Hedges Digital Logo"
              className="m0"
              src={logo}
            />
          </StyledLogoLink>
        </LogoLinkWrapper>
      </HeaderWrapper>
    </CenteredDiv>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
